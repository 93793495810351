import { History } from "history";
import * as _ from "lodash";
import { Dispatch } from "redux";
import { reset as resetForm } from "redux-form";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
    CLEAR_PRONUNCIATION_STATE,
    CREATE_PRONUNCIATION_FAILURE,
    CREATE_PRONUNCIATION_REQUEST,
    CREATE_PRONUNCIATION_SUCCESS,
    DELETE_PRONUNCIATION_FAILURE,
    DELETE_PRONUNCIATION_REQUEST,
    DELETE_PRONUNCIATION_SUCCESS,
    EDIT_PRONUNCIATION_FAILURE,
    EDIT_PRONUNCIATION_REQUEST,
    EDIT_PRONUNCIATION_SUCCESS,
    FETCH_PRONUNCIATION_FAILURE,
    FETCH_PRONUNCIATION_REQUEST,
    FETCH_PRONUNCIATION_SUCCESS,
    FETCH_PRONUNCIATIONS_FAILURE,
    FETCH_PRONUNCIATIONS_REQUEST,
    FETCH_PRONUNCIATIONS_SUCCESS,
} from "../constants/pronunciation";
import { IState } from "../reducers";
import HttpMethods from "../types/http-methods";
import IPronunciation from "../types/pronunciation";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import IUser from "../types/user";
import { canVerifyPronuncation } from "../utils/pronunciation-utils";

/*
    ASYNC ACTIONS
*/

export const fetchPronunciations =
    (params?: IParams, reset?: boolean) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: FETCH_PRONUNCIATIONS_REQUEST });

        const state = getState();

        const url = buildUrl(`${config.api.url}${config.api.paths.pronunciation}`, {
            ...params,
            offset: reset ? 0 : state.pronunciation.offset,
            include: ["users:creator"],
        });

        return fetch(url)
            .then(responseCheck)
            .then((pronunciations) => fetchPronunciationsSuccess(dispatch, pronunciations, state.pronunciation.pronunciations, reset ? reset : false))
            .catch((error) => dispatch(fetchPronunciationsFailure(error)));
    };

export const createPronunciation =
    (pronunciation: IPronunciation, history?: History, other?: { hideToast?: boolean }) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: CREATE_PRONUNCIATION_REQUEST });
        pronunciation.pronunciationName = _.trim(pronunciation.pronunciationName);

        return fetch(`${config.api.url}${config.api.paths.pronunciation}`, {
            body: JSON.stringify({ model: pronunciation }),
            method: HttpMethods.POST,
        })
            .then(responseCheck)
            .then((pron) => dispatch(createPronunciationSuccess(pron, other?.hideToast)))
            .then((data) => {
                if (history) {
                    history.push(config.paths.pronunciation);
                } else {
                    dispatch(resetForm("PronunciationForm"));
                }
                return data;
            })
            .catch((error) => dispatch(createPronunciationFailure(error, other?.hideToast)));
    };

export const editPronunciation =
    (pronunciation: IPronunciation, user?: IUser, history?: History, isVerification?: boolean, other?: { hideToast?: boolean; skipReduxUpdates?: boolean }) =>
    (dispatch: Dispatch): Promise<any> => {
        if (!other?.skipReduxUpdates) {
            dispatch({ type: EDIT_PRONUNCIATION_REQUEST });
        }

        pronunciation.pronunciationName = _.trim(pronunciation.pronunciationName);

        // @ts-ignore
        pronunciation.verifiedAt = null;

        return fetch(`${config.api.url}${config.api.paths.pronunciation}/${pronunciation.pronunciationId}`, {
            body: JSON.stringify({ model: pronunciation }),
            method: HttpMethods.PUT,
        })
            .then(responseCheck)

            .then(() => {
                if (!other?.skipReduxUpdates) {
                    dispatch(editPronunciationSuccess(pronunciation, user, other?.hideToast));
                }
            })
            .then(() => history && history.push(config.paths.pronunciation))
            .catch((error) => dispatch(editPronunciationFailure(error, other?.hideToast)));
    };

export const verifyPronunciation =
    (pronunciation: IPronunciation, user?: IUser, history?: History, isVerification?: boolean) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: EDIT_PRONUNCIATION_REQUEST });
        pronunciation.pronunciationName = _.trim(pronunciation.pronunciationName);

        if (!canVerifyPronuncation(pronunciation, user)) {
            dispatch(verifyPronunciationFailure(new Error(`You cannot verify pronunciation '${pronunciation.pronunciationName}' as you were the last to edit it.`)));
            return new Promise(() => null);
        }

        pronunciation.verifiedAt = new Date();

        return fetch(`${config.api.url}${config.api.paths.pronunciation}/${pronunciation.pronunciationId}`, {
            body: JSON.stringify({ model: pronunciation }),
            method: HttpMethods.PUT,
        })
            .then(responseCheck)
            .then(() => dispatch(verifyPronunciationSuccess(pronunciation, user)))
            .then(() => history && history.push(config.paths.pronunciation))
            .catch((error) => dispatch(verifyPronunciationFailure(error)));
    };

export const deletePronunciation =
    (pronunciation: IPronunciation, other?: { hideToast?: boolean; skipReduxUpdates?: boolean }) =>
    (dispatch: Dispatch): Promise<any> => {
        if (!other?.skipReduxUpdates) {
            dispatch({ type: DELETE_PRONUNCIATION_REQUEST });
        }

        pronunciation.pronunciationName = _.trim(pronunciation.pronunciationName);

        return fetch(`${config.api.url}${config.api.paths.pronunciation}/${pronunciation.pronunciationId}`, {
            method: HttpMethods.DELETE,
        })
            .then(responseCheck)
            .then(() => {
                if (!other?.skipReduxUpdates) {
                    dispatch(deletePronunciationSuccess(pronunciation));
                }
            })
            .catch((error) => dispatch(deletePronunciationFailure(error, other?.hideToast)));
    };

export const fetchPronunciation = (id: number) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PRONUNCIATION_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.pronunciation}/${id}`)
        .then(responseCheck)
        .then((pronunciation) => dispatch(fetchPronunciationSuccess(pronunciation)))
        .catch((error) => dispatch(fetchPronunciationFailure(error)));
};

/*
    SYNC ACTIONS
*/

export const clearPronunciationState = () => {
    return { type: CLEAR_PRONUNCIATION_STATE };
};

const fetchPronunciationsSuccess = (dispatch: Dispatch, pronunciations: IPronunciation[], oldPronunciations: IPronunciation[], reset: boolean) => {
    const newPronunciations = !reset ? [...oldPronunciations, ...pronunciations] : pronunciations;

    return dispatch({
        offset: newPronunciations.length,
        pronunciations: newPronunciations,
        type: FETCH_PRONUNCIATIONS_SUCCESS,
    });
};

const fetchPronunciationsFailure = (error: Error) => {
    toast("Error fetching pronunciations");
    return { type: FETCH_PRONUNCIATIONS_FAILURE, error };
};

const createPronunciationSuccess = (pronunciation: IPronunciation, hideToast?: boolean) => {
    if (!hideToast) {
        toast("Pronunciation created");
    }
    return { type: CREATE_PRONUNCIATION_SUCCESS, pronunciation };
};

const createPronunciationFailure = (error: Error, hideToast?: boolean) => {
    if (!hideToast) {
        toast("Error creating pronunciation");
    }
    return { type: CREATE_PRONUNCIATION_FAILURE, error };
};

export const verifyPronunciationSuccess = (pronunciation: IPronunciation, user?: IUser) => {
    toast(`Pronunciation '${pronunciation.pronunciationName}' has been verified!`);
    const editedPronunciation = {
        ...pronunciation,
        editedBy: user ? user.userId : undefined,
    };
    return { type: EDIT_PRONUNCIATION_SUCCESS, editedPronunciation };
};

export const editPronunciationSuccess = (pronunciation: IPronunciation, user?: IUser, hideToast?: boolean) => {
    if (!hideToast) {
        toast("Pronunciation edited");
    }

    const editedPronunciation = {
        ...pronunciation,
        editedBy: user ? user.userId : undefined,
    };
    return { type: EDIT_PRONUNCIATION_SUCCESS, editedPronunciation };
};

export const editPronunciationFailure = (error: Error, hideToast?: boolean) => {
    if (!hideToast) {
        toast("Error editing pronunciation");
    }
    return { type: EDIT_PRONUNCIATION_FAILURE, error };
};

export const verifyPronunciationFailure = (error: Error) => {
    toast(error.message);
    return { type: EDIT_PRONUNCIATION_FAILURE, error };
};

export const fetchPronunciationSuccess = (pronunciation: IPronunciation) => {
    return { type: FETCH_PRONUNCIATION_SUCCESS, pronunciation };
};

export const fetchPronunciationFailure = (error: Error) => {
    toast("Error fetching pronunciation");
    return { type: FETCH_PRONUNCIATION_FAILURE, error };
};

export const deletePronunciationFailure = (error: Error, hideToast?: boolean) => {
    if (!hideToast) {
        toast("Error deleting pronunciation");
    }
    return { type: DELETE_PRONUNCIATION_FAILURE, error };
};

export const deletePronunciationSuccess = (deletedPronunciation: IPronunciation) => {
    return { type: DELETE_PRONUNCIATION_SUCCESS, deletedPronunciation };
};
